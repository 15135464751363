<template>
    <div>
        <div class="navbar bg-base-100 flex">
            <div class="navbar-start">
                <div class="indicator">
                    <div class="dropdown sm:hidden">
                        <div tabindex="0" role="button" class="btn btn-ghost btn-circle" @click="showSideBar">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M4 6h16M4 12h16M4 18h7"/>
                            </svg>
                        </div>
                    </div>
                    <a class="btn btn-ghost normal-case text-xl">Music Tag Web</a>
                    <div class="!hidden md:!block">
                        <div class="badge badge-neutral cursor-pointer" v-if="role === 'V1'" @click="showActiceCode = true">
                            {{ role }}
                        </div>
                        <div class="badge badge-neutral cursor-pointer" v-else @click="showActiceCode = true">{{ role }}</div>
                    </div>
                </div>
            </div>
            <div class="navbar-center !hidden md:!block">
                <ul class="menu menu-horizontal px-1" v-if="role === 'V2'">
                    <li v-for="(item,index) in header.list" :key="index">
                        <a @click="redirectUrl(item)" :class="{ 'active': item.pathName === navName }">{{
                                item.name
                            }}</a>
                    </li>
                </ul>
                <ul class="menu menu-horizontal px-1" v-else>
                    <li v-for="(item,index) in header.list" :key="index">
                        <a @click="redirectUrl(item)" :class="{ 'active': item.pathName === navName }"
                            v-if="role === item.role">{{ item.name }}</a>
                    </li>
                </ul>
            </div>
            <div class="navbar-end">
                <div style="display: flex;justify-content: center;align-items: center;">
                    <div class="relative" v-show="isSearch">
                        <div class="absolute w-72 -left-[250px] top-[46px] z-[11]">
                            <input type="text" ref="inputVal" class="input input-bordered input-sm w-full max-w-xs"
                                placeholder="搜索关键词" v-model="searchTerm" @input="handleInput" autocomplete="off">
                            </input>
                            <ul class="absolute w-full bg-base-100 border border-base-100 rounded-md mt-2 shadow-lg"
                                v-if="showResults">
                                <div v-if="suggestions.artists.length > 0">
                                    <li class="ml-2 mt-2 text-base">艺术家</li>
                                    <li v-for="(artist, index) in suggestions.artists" :key="'at' + index"
                                        class="py-2 px-4 cursor-pointer hover:bg-gray-100"
                                        @click="redirectArtist(artist)">
                                        <div class="text-sm text-neutral-content">{{ artist.name }} -
                                            {{ artist.albumCount }}专辑
                                        </div>
                                    </li>
                                    <hr>
                                </div>
                                <div v-if="suggestions.albums.length > 0">
                                    <li class="ml-2 mt-2">专辑</li>
                                    <li v-for="(album, index) in suggestions.albums" :key="'al' + index"
                                        class="py-2 px-4 cursor-pointer hover:bg-gray-100"
                                        @click="redirectAlbum(album)">
                                        <div :key="'album' + index" class="text-sm text-neutral-content">
                                            {{ album.name }} - {{ album.year }} - {{ album.artist }}
                                        </div>
                                    </li>
                                    <hr>
                                </div>
                                <div v-if="suggestions.tracks.length > 0">
                                    <li class="ml-2 mt-2">歌曲</li>
                                    <li v-for="(track, index) in suggestions.tracks" :key="'tr' + index"
                                        class="py-2 px-4 cursor-pointer hover:bg-gray-100"
                                        @click="redirectTrack(track)">
                                        <div :key="'tra' + index" class="text-sm text-neutral-content">
                                            {{ track.title }} - {{ track.year }} - {{ track.artist }} - {{
                                                track.album
                                            }}
                                        </div>
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <button class="btn btn-ghost btn-circle" @click="handleClick" v-if="role === 'V2'">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                        </svg>
                    </button>

                    <label class="swap swap-rotate">

                        <!-- this hidden checkbox controls the state -->
                        <input type="checkbox" @click="isShowTheme=true"/>

                        <!-- sun icon -->
                        <svg class="swap-on fill-current w-5 h-5" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path
                                d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z"/>
                        </svg>

                        <!-- moon icon -->
                        <svg class="swap-off fill-current w-5 h-5" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path
                                d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"/>
                        </svg>

                    </label>
                    <div class="dropdown dropdown-end">
                        <label tabindex="0" class="btn btn-ghost btn-circle" @click="submitRecord">
                            <div class="indicator">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
                                </svg>
                                <span class="badge badge-sm indicator-item badge-secondary"
                                    v-if="unreadCount !== 0">{{ unreadCount }}</span>
                            </div>
                        </label>
                        <div tabindex="0" class="mt-3 z-[1] card card-compact dropdown-content w-80 bg-base-100 shadow">
                            <div class="card-body overflow-y-auto">
                                <ul class="message-list flex flex-col">
                                    <li class="flex justify-between items-center cursor-pointer hover:bg-gray-100 my-2"
                                        v-for="(item,index) in msgList" :key="index">
                                        <div class="flex flex-col">
                                            <span class="text-xs text-gray-500">{{ item.title }}</span>
                                            <span class="text-xs text-gray-500">{{ item.content }}</span>
                                        </div>
                                        <span class="text-xs text-gray-500">{{ item.update_at }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown dropdown-end">
                        <label tabindex="0" class="btn btn-ghost btn-circle avatar">
                            <div class="w-10 rounded-full">
                                <img src="/static/dist/img/avator.webp"/>
                            </div>
                        </label>
                        <ul tabindex="0"
                            class="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                            <li class="nav-item" @click="handleUserListClic2k">
                                <a class="justify-between">
                                    后台管理{{ refresh }}
                                    <span class="badge">{{ this.username }}</span>
                                </a>
                            </li>
                            <li class="nav-item" @click="handleUserListClic3k">
                                <a class="justify-between">
                                    使用手册
                                    <span class="badge">v{{ version }}</span>
                                </a>
                            </li>
                            <li class="nav-item" @click="handleUserListClick">
                                <a class="justify-between">
                                    关于作者
                                    <span class="badge">{{ versionNum }}</span>
                                </a>
                            </li>
                            <li class="nav-item" @click="handleUserListClic4k">
                                <a>
                                    退出登录
                                    <span class="badge">Logout</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <input type="checkbox" id="my_modal_6" class="modal-toggle" v-model="showActiceCode"/>
                <div class="modal" role="dialog">
                    <div class="modal-box">
                        <div v-if="!isForever">
                            <h3 class="font-bold text-lg" v-if="role === 'V1'">激活 V2 版本</h3>
                            <h3 class="font-bold text-lg" v-else>升级 V2 永久版本</h3>
                            <label class="form-control w-full max-w-xs mt-4">
                                <div class="label">
                                    <span class="label-text">输入你的激活码：</span>
                                </div>
                                <input type="text" placeholder="请输入" class="input input-bordered w-full max-w-xs"
                                       v-model="code"/>
                            </label>
                            <div class="modal-action">
                                <label for="my_modal_6" class="btn btn-md btn-neutral" @click="handleActiveCode">激活</label>
                                <label for="my_modal_6" class="btn btn-md">关闭</label>
                            </div>
                        </div>
                        <div v-else>
                            您已经是永久激活用户！
                            <div class="modal-action">
                                <label for="my_modal_6" class="btn btn-md">关闭</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="drawer">
            <input id="edit-show-bar" type="checkbox" class="drawer-toggle" v-model="isShowSideBar"/>
            <div class="drawer-content">
            </div>
            <div class="drawer-side z-20">
                <label for="edit-show-bar" aria-label="close sidebar" class="drawer-overlay"></label>
                <div class="p-4 min-h-full bg-base-100 text-base-content">
                    <Side/>
                </div>
            </div>
        </div>
        <div class="drawer drawer-end">
            <input id="drawer-theme" type="checkbox" class="drawer-toggle" v-model="isShowTheme"/>
            <div class="drawer-content">
                <!-- Page content here -->
            </div>
            <div class="drawer-side z-20">
                <label for="drawer-theme" aria-label="close sidebar" class="drawer-overlay"></label>
                <div class="p-8 w-80 min-h-full bg-base-200 text-base-content">
                    <div class="text-xl font-medium mb-1">切换主题: {{ this.currentTheme }}</div>
                    <div class="rounded-box grid grid-cols-1 gap-4">
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="light" data-act-class="!outline-base-content" @click="switchTheme('light')">
                            <div data-theme="light"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">light</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="dark" data-act-class="!outline-base-content" @click="switchTheme('dark')">
                            <div data-theme="dark"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">dark</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="cupcake" data-act-class="!outline-base-content"
                            @click="switchTheme('cupcake')">
                            <div data-theme="cupcake"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">cupcake</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="bumblebee" data-act-class="!outline-base-content"
                            @click="switchTheme('bumblebee')">
                            <div data-theme="bumblebee"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">bumblebee</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="emerald" data-act-class="!outline-base-content"
                            @click="switchTheme('emerald')">
                            <div data-theme="emerald"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">emerald</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="emerald" data-act-class="!outline-base-content"
                            @click="switchTheme('charles')">
                            <div data-theme="charles"
                                 class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">charles</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="corporate" data-act-class="!outline-base-content"
                            @click="switchTheme('corporate')">
                            <div data-theme="corporate"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">corporate</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="synthwave" data-act-class="!outline-base-content"
                            @click="switchTheme('synthwave')">
                            <div data-theme="synthwave"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">synthwave</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="retro" data-act-class="!outline-base-content" @click="switchTheme('retro')">
                            <div data-theme="retro"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">retro</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="cyberpunk" data-act-class="!outline-base-content"
                            @click="switchTheme('cyberpunk')">
                            <div data-theme="cyberpunk"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">cyberpunk</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="valentine" data-act-class="!outline-base-content"
                            @click="switchTheme('valentine')">
                            <div data-theme="valentine"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">valentine</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="halloween" data-act-class="!outline-base-content"
                            @click="switchTheme('halloween')">
                            <div data-theme="halloween"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">halloween</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="garden" data-act-class="!outline-base-content"
                            @click="switchTheme('garden')">
                            <div data-theme="garden"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">garden</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="forest" data-act-class="!outline-base-content"
                            @click="switchTheme('forest')">
                            <div data-theme="forest"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">forest</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="aqua" data-act-class="!outline-base-content" @click="switchTheme('aqua')">
                            <div data-theme="aqua"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">aqua</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="lofi" data-act-class="!outline-base-content" @click="switchTheme('lofi')">
                            <div data-theme="lofi"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">lofi</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="pastel" data-act-class="!outline-base-content"
                            @click="switchTheme('pastel')">
                            <div data-theme="pastel"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">pastel</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="fantasy" data-act-class="!outline-base-content"
                            @click="switchTheme('fantasy')">
                            <div data-theme="fantasy"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">fantasy</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="wireframe" data-act-class="!outline-base-content"
                            @click="switchTheme('wireframe')">
                            <div data-theme="wireframe"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">wireframe</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="black" data-act-class="!outline-base-content" @click="switchTheme('black')">
                            <div data-theme="black"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">black</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="luxury" data-act-class="!outline-base-content"
                            @click="switchTheme('luxury')">
                            <div data-theme="luxury"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">luxury</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="dracula" data-act-class="!outline-base-content"
                            @click="switchTheme('dracula')">
                            <div data-theme="dracula"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">dracula</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="cmyk" data-act-class="!outline-base-content" @click="switchTheme('cmyk')">
                            <div data-theme="cmyk"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">cmyk</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="autumn" data-act-class="!outline-base-content"
                            @click="switchTheme('autumn')">
                            <div data-theme="autumn"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">autumn</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="business" data-act-class="!outline-base-content"
                            @click="switchTheme('business')">
                            <div data-theme="business"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">business</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="acid" data-act-class="!outline-base-content" @click="switchTheme('acid')">
                            <div data-theme="acid"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">acid</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="lemonade" data-act-class="!outline-base-content"
                            @click="switchTheme('lemonade')">
                            <div data-theme="lemonade"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">lemonade</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="night" data-act-class="!outline-base-content" @click="switchTheme('night')">
                            <div data-theme="night"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">night</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="coffee" data-act-class="!outline-base-content"
                            @click="switchTheme('coffee')">
                            <div data-theme="coffee"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">coffee</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="winter" data-act-class="!outline-base-content"
                            @click="switchTheme('winter')">
                            <div data-theme="winter"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">winter</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="dim" data-act-class="!outline-base-content" @click="switchTheme('dim')">
                            <div data-theme="dim" class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">dim</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="nord" data-act-class="!outline-base-content" @click="switchTheme('nord')">
                            <div data-theme="nord"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">nord</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                            data-set-theme="sunset" data-act-class="!outline-base-content"
                            @click="switchTheme('sunset')">
                            <div data-theme="sunset"
                                class="bg-base-100 text-base-content w-full cursor-pointer font-sans">
                                <div class="grid grid-cols-5 grid-rows-3">
                                    <div class="bg-base-200 col-start-1 row-span-2 row-start-1"></div>
                                    <div class="bg-base-300 col-start-1 row-start-3"></div>
                                    <div
                                        class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                                        <div class="font-bold">sunset</div>
                                        <div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
                                            <div
                                                class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-primary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-secondary-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-accent-content text-sm font-bold">A</div>
                                            </div>
                                            <div
                                                class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                                                <div class="text-neutral-content text-sm font-bold">A</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {clearStore} from '../../../common/store.js'
    import {mapGetters} from 'vuex'
    import Side from '@/components/base/magicMenu/side'

    export default {
        components: {
            Side
        },
        data() {
            return {
                logout_url: 'https://github.com/xhongc/music-tag-web',
                pageTitle: '测试',
                currentTheme: 'light',
                userData: {},
                role: '',
                isForever: false,
                username: '',
                msgList: [],
                unreadCount: 0,
                showResults: false,
                isSearch: false,
                suggestions: {},
                searchTerm: '',
                syncSwitch: true,
                showActiceCode: false,
                user: {
                    list: [
                        '关于作者'
                    ]
                },
                header: {
                    list: [
                        {
                            name: '首页',
                            id: 3,
                            show: true,
                            pathName: 'index',
                            role: 'V2'
                        },
                        {
                            name: '操作台',
                            id: 1,
                            show: true,
                            pathName: 'home',
                            role: 'V1'
                        },
                        {
                            name: '操作记录',
                            id: 6,
                            show: true,
                            pathName: 'log',
                            role: 'V1'
                        },
                        {
                            name: '插件管理',
                            id: 4,
                            show: true,
                            pathName: 'mod',
                            role: 'V2'
                        },
                        {
                            name: '音乐收藏',
                            id: 2,
                            show: true,
                            pathName: 'library',
                            role: 'V2'
                        },
                        {
                            name: '系统设置',
                            id: 5,
                            show: true,
                            pathName: 'system',
                            role: 'V2'
                        }
                    ],
                    active: -1,
                },
                code: '',
                isShowSideBar: false,
                isShowTheme: false,
                colorMap: {
                    'light': '#f2f2f2',
                    'dark': '#1a1e23',
                    'cupcake': '#eeeae6',
                    'bumblebee': '#e8e8e8',
                    'emerald': '#e8e8e8',
                    'corporate': '#e8e8e8',
                    'synthwave': '#150e34',
                    'retro': '#e2d8b8',
                    'cyberpunk': '#e7dc5f',
                    'valentine': '#e0d2dd',
                    'halloween': '#1d1d1d',
                    'garden': '#d3d2d2',
                    'forest': '#130f0f',
                    'aqua': '#375393',
                    'lofi': '#f2f2f2',
                    'pastel': '#f9fafb',
                    'fantasy': '#e8e8e8',
                    'wireframe': '#eeeeee',
                    'black': '#141414',
                    'luxury': '#171618',
                    'dracula': '#24252f',
                    'cmyk': '#e8e8e8',
                    'autumn': '#dbdbdb',
                    'business': '#1c1c1c',
                    'acid': '#e3e3e3',
                    'lemonade': '#e2e6da',
                    'night': '#0e1424',
                    'coffee': '#1b131b',
                    'winter': '#f3f7fe',
                    'dim': '#252932',
                    'nord': '#e6e9ef',
                    'sunset': '#10171d'
                }
            }
        },
        computed: {
            ...mapGetters(['getHasMsg']),
            refresh() {
                if (this.getHasMsg) {
                    this.$store.commit('setHasMsg', false)
                    this.fetchRecord()
                }
            },
            headerTitle() {
                return this.$route.meta.title
            },
            navName() {
                const name = this.$route.meta.hasOwnProperty('fatherName') ? this.$route.meta.fatherName : this.$route.name
                return name
            },
            version() {
                return window.projectVersion
            },
            versionNum() {
                return window.projectVersionNum
            }
        },
        mounted() {
            this.loginUser()
        },
        created() {
            this.initTheme()
            this.fetchRecord()
        },
        methods: {
            initTheme() {
                if (localStorage.getItem('theme')) {
                    this.currentTheme = localStorage.getItem('theme')
                }
                document.documentElement.setAttribute('data-theme', this.currentTheme)
                localStorage.setItem('theme', this.currentTheme)
                // 获取<meta name="theme-color">元素
                let metaThemeColor = document.querySelector('meta[name="theme-color"]')
                // 检查是否找到了元素
                if (metaThemeColor) {
                    // 修改它的content属性值
                    metaThemeColor.setAttribute('content', this.colorMap[this.currentTheme])
                }
            },
            switchTheme(theme) {
                this.currentTheme = theme
                document.documentElement.setAttribute('data-theme', this.currentTheme)
                localStorage.setItem('theme', this.currentTheme)
                // 获取<meta name="theme-color">元素
                let metaThemeColor = document.querySelector('meta[name="theme-color"]')
                // 检查是否找到了元素
                if (metaThemeColor) {
                    // 修改它的content属性值
                    metaThemeColor.setAttribute('content', this.colorMap[this.currentTheme])
                }
            },
            handleSwitchSync(v) {
                this.$api.Task.UpdateSys({
                    'key': 'is_watch_file',
                    'value': v ? '1' : '0'
                }).then((res) => {
                    if (!res.result) {
                        this.syncSwitch = !v
                    }
                })
            },
            handleRedirect(item) {
                if (this.$route.name !== 'home') {
                    this.$router.push({name: 'home'})
                }
                this.$store.commit('setFullPath', item.full_path)
            },
            handleUserListClick(e) {
                const btn = document.createElement('a')
                btn.setAttribute('href', this.logout_url)
                document.body.appendChild(btn)
                btn.click()
                clearStore()
            },
            handleUserListClic2k(e) {
                const btn = document.createElement('a')
                btn.setAttribute('href', '/admin/')
                document.body.appendChild(btn)
                btn.click()
                clearStore()
            },
            handleUserListClic3k(e) {
                const btn = document.createElement('a')
                if (this.role === 'V2') {
                    btn.setAttribute('href', 'https://xiers-organization.gitbook.io/music-tag-web-v2')
                } else {
                    btn.setAttribute('href', 'https://xiers-organization.gitbook.io/music-tag-web/')
                }
                document.body.appendChild(btn)
                btn.click()
                clearStore()
            },
            handleUserListClic4k(e) {
                this.$router.push({name: 'login'})
            },
            loginUser() {
                this.$api.Task.loginInfo().then((res) => {
                    if (res.result) {
                        this.userData = res.data
                        this.role = res.data.role
                        this.isForever = res.data.is_forever
                        this.username = res.data.username
                        this.$store.commit('setUserRole', res.data.role)
                        this.$store.commit('setUsername', res.data.username)
                        this.setCookie('username', res.data.username, 7)
                        this.setCookie('salt', res.data.salt, 7)
                        this.setCookie('hash', res.data.hash, 7)
                        window.localStorage.setItem('mi_play_in_pl', res.data.sys_dict.mi_play_in_pl)
                    } else {
                        this.$router.push({name: 'login'})
                    }
                })
            },
            getSysSetting() {
                this.$api.Task.getSys({'key': 'is_watch_file'}).then((res) => {
                    if (res.result) {
                        this.syncSwitch = res.data.items[0].value === '1'
                    }
                })
            },
            handleBack() {
                this.$router.go(-1)
            },
            fetchRecord() {
                this.$api.Task.getRecord({'state': 'failed', 'page_size': 20}).then((res) => {
                    if (res.result) {
                        this.msgList = res.data.items.filter(item => item.version >= window.projectVersion);
                        this.unreadCount = res.data.unread_count
                    }
                })
            },
            submitRecord() {
              this.$api.Task.postRecord(this.msgList).then((res) => {
                  if (res.result) {
                      this.unreadCount = 0
                  }
              })
            },
            redirectUrl(item) {
                this.header.active = item.id
                if (item.pathName === 'library') {
                    this.$router.push({path: '/albums/recently-added/'})
                } else {
                    this.$router.push({name: item.pathName})
                }
            },
            handleInput() {
                if (this.searchTerm.length > 0) {
                    // 发送AJAX请求获取联想结果
                    // 假设返回的结果为一个数组，存储在suggestions变量中
                    this.$api.subsonic.search(this.searchTerm).then((res) => {
                        this.suggestions = res
                        this.showResults = true
                        console.log(res)
                    })
                } else {
                    this.suggestions = {}
                    this.showResults = false
                }
            },
            redirectArtist(item) {
                this.isSearch = false
                this.$router.push({path: '/artist/id/' + item.id})
            },
            redirectAlbum(item) {
                this.isSearch = false
                this.$router.push({path: '/album/id/' + item.id})
            },
            redirectTrack(item) {
                this.isSearch = false
                this.$router.push({path: '/album/id/' + item.albumId})
            },
            handleClick() {
                this.isSearch = !this.isSearch
                this.searchTerm = ''
                this.$nextTick(function() {
                    this.$refs.inputVal.focus()
                })
            },
            handleActiveCode() {
                this.$api.Task.activeCode({'code': this.code}).then((res) => {
                    if (res.result) {
                        window.location.reload()
                    } else {
                        this.$cwMessage(res.message, 'error')
                    }
                })
            },
            showSideBar() {
                this.isShowSideBar = !this.isShowSideBar
            }
        }
    }
</script>

<style scoped>
.monitor-navigation-header {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}

.monitor-navigation-header .header-title {
    color: #63656E;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: -6px;
}

.monitor-navigation-header .header-title-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 28px;
    height: 28px;
    font-size: 28px;
    color: #3A84FF;
    cursor: pointer;
}

.monitor-navigation-header .header-select {
    width: 240px;
    margin-left: auto;
    margin-right: 34px;
    border: none;
    background: #f0f1f5;
    color: #63656e;
    -webkit-box-shadow: none;
    box-shadow: none
}

.monitor-navigation-header .header-user {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #96A2B9;
    /*       margin-left: 8px; */
}

.monitor-navigation-header .header-user .bk-icon {
    margin-left: 5px;
    font-size: 12px;
}

.monitor-navigation-header .header-user:hover {
    cursor: pointer;
    color: #3A84FF
}

.monitor-navigation-admin {
    width: 170px #63656E;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    -webkit-box-shadow: 0px 3px 4px 0px rgba(64, 112, 203, 0.06);
    box-shadow: 0px 3px 4px 0px rgba(64, 112, 203, 0.06);
    padding: 6px 0;
    margin: 0;
    color: #63656E;
}

.monitor-navigation-admin .nav-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 32px;
    flex: 0 0 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 20px;
    list-style: none
}

.monitor-navigation-admin .nav-item:hover {
    color: #3A84FF;
    cursor: pointer;
    background-color: #F0F1F5;
}

.navigation-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-size: 14px;
}

.monitor-navigation-header .header-mind {
    color: #768197;
    font-size: 16px;
    position: relative;
    height: 32px;
    width: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 8px
}

.monitor-navigation-header .header-mind.is-left {
    color: #63656E;
}

.monitor-navigation-header .header-mind.is-left:hover {
    color: #3A84FF;
    background: #F0F1F5
}

.monitor-navigation-header .header-mind-mark {
    position: absolute;
    right: 8px;
    top: 8px;
    height: 7px;
    width: 7px;
    border: 1px solid #27334C;
    background-color: #EA3636;
    border-radius: 100%
}

.monitor-navigation-header .header-mind-mark.is-left {
    border-color: #F0F1F5;
}

.monitor-navigation-header .header-mind:hover {
    background: -webkit-gradient(linear, right top, left top, from(rgba(37, 48, 71, 1)), to(rgba(38, 50, 71, 1)));
    background: linear-gradient(270deg, rgba(37, 48, 71, 1) 0%, rgba(38, 50, 71, 1) 100%);
    border-radius: 100%;
    cursor: pointer;
    color: #D3D9E4;
}

.monitor-navigation-header .header-mind .lang-icon {
    font-size: 20px;
}

.monitor-navigation-header .header-mind-mark {
    position: absolute;
    right: 8px;
    top: 8px;
    height: 7px;
    width: 7px;
    border: 1px solid #27334C;
    background-color: #EA3636;
    border-radius: 100%
}

.monitor-navigation-header .header-mind-mark.is-left {
    border-color: #F0F1F5;
}

.monitor-navigation-message {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 360px;
    background-color: #FFFFFF;
    border: 1px solid #E2E2E2;
    border-radius: 2px;
    -webkit-box-shadow: 0px 3px 4px 0px rgba(64, 112, 203, 0.06);
    box-shadow: 0px 3px 4px 0px rgba(64, 112, 203, 0.06);
    color: #979BA5;
    font-size: 12px;
}

.monitor-navigation-message .message-title {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 48px;
    flex: 0 0 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #313238;
    font-size: 14px;
    padding: 0 20px;
    margin: 0;
    border-bottom: 1px solid #F0F1F5;
}

.monitor-navigation-message .message-list {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    max-height: 450px;
    overflow: auto;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
}

.monitor-navigation-message .message-list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 0 20px;
}

.monitor-navigation-message .message-list-item .item-message {
    padding: 13px 0;
    line-height: 16px;
    min-height: 42px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    color: #63656E;
}

.monitor-navigation-message .message-list-item .item-date {
    padding: 13px 0;
    margin-left: 16px;
    color: #979BA5;
}

.monitor-navigation-message .message-list-item:hover {
    cursor: pointer;
    background: #F0F1F5;
}

.monitor-navigation-message .message-footer {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 42px;
    flex: 0 0 42px;
    border-top: 1px solid #F0F1F5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #3A84FF;
}

.header-nav-item {
    list-style: none;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 40px;
    color: #96A2B9;
}

.header-nav-item:hover {
    cursor: pointer;
    color: #000;
}

.header-nav-item::before {
    content: "";
    position: absolute;
    left: 25%;
    bottom: 10px;
    width: 0;
    height: 2px;
    background: rgb(1, 158, 213);
    transition: all .3s;
}

.header-nav-item:hover::before {
    width: calc(100% - 40px);
    left: 0;
    right: 0;
}

.item-active {
    color: rgb(3, 41, 81);
}

.item-active::before {
    color: #000;
    content: "";
    position: absolute;
    left: 0;
    bottom: 10px;
    height: 2px;
    background: rgb(1, 158, 213);
    width: calc(100% - 40px);
}
</style>

<style>
.tippy-popper .tippy-tooltip.navigation-message-theme {
    padding: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
</style>
