import router from './router'
import store from '@/vuex/index'
import Home from '@/views/home/home'
import User from '@/views/user/index'
import Login from '@/views/user/login'
import Library from '@/views/library/index'
import Index from '@/views/index/index'
import ProgressRate from '@/views/index/progressRate'
import Record from '@/views/index/record'
import Mod from '@/views/mod/index'
import ActionMod from '@/views/mod/actionMod/index'
import System from '@/views/system/index'
import Log from '@/views/log/index'
import logDetail from '@/views/log/logDetail'
import AlbumLibrary from '@/views/library/album/AlbumLibrary'
import AlbumDetails from './views/library/album/AlbumDetails'
import ArtistLibrary from './views/library/artist/ArtistLibrary'
import ArtistDetails from './views/library/artist/ArtistDetails'
import ArtistTracks from './views/library/artist/ArtistTracks'
import TrackLibrary from './views/library/track/TrackLibrary'
import GenreLibrary from '@/views/library/genre/GenreLibrary'
import GenreDetails from '@/views/library/genre/GenreDetails'
import Favourites from '@/views/library/favourite/Favourites'
import Playing from '@/views/library/playing/Queue'
import PlayingList from '@/views/library/playing/PlayingDetails'
import PlayListLibrary from '@/views/library/playlist/PlaylistLibrary'
import PlayList from '@/views/library/playlist/Playlist'
// const _import = require('./router/_import_' + process.env.NODE_ENV) // 获取组件的方法

let getRouter // 用来获取后台拿到的路由
// let getMenuList // 用来获取后台拿到的菜单
saveObjArr('router', '')

router.beforeEach((to, from, next) => {
    // console.log(getRouter)
    if (!getRouter) { // 不加这个判断，路由会陷入死循环
        if (!getObjArr('router')) {
            getRouter = [
                {
                    'path': '/',
                    'name': 'home',
                    'component': 'Home',
                    'meta': {
                        'title': '控制台'
                    }
                },
                {
                    'path': '/log',
                    'name': 'log',
                    'component': 'Log',
                    'meta': {
                        'title': '操作记录'
                    }
                },
                {
                    'path': '/log_detail/:id/',
                    'name': 'log_detail',
                    'component': 'logDetail',
                    'meta': {
                        'title': '日志详情',
                        'fatherName': 'log'
                    }
                },
                {
                    'path': '/user',
                    'name': 'user',
                    'component': 'User',
                    'meta': {
                        'title': '用户信息'
                    }
                },
                {
                    'path': '/library',
                    'name': 'library',
                    'component': 'Library',
                    'meta': {
                        'title': '音乐收藏馆'
                    },
                    'children': [
                        {
                            'path': '/albums/:sort',
                            'name': 'albums',
                            'component': 'AlbumLibrary',
                            'meta': {
                                'title': '专辑收藏',
                                'fatherName': 'library'
                            }
                        },
                        {
                            'path': '/album/id/:id',
                            'name': 'album',
                            'component': 'AlbumDetails',
                            'meta': {
                                'title': '专辑详情',
                                'fatherName': 'library'
                            }
                        },
                        {
                            'path': '/tracks/:sort',
                            'name': 'tracks',
                            'component': 'TrackLibrary',
                            'meta': {
                                'title': '歌曲收藏',
                                'fatherName': 'library'
                            }
                        },
                        {
                            'path': '/artists/:sort',
                            'name': 'artists',
                            'component': 'ArtistLibrary',
                            'meta': {
                                'title': '艺术家列表',
                                'fatherName': 'library'
                            }
                        },
                        {
                            'path': '/artist/id/:id',
                            'name': 'artist',
                            'component': 'ArtistDetails',
                            'meta': {
                                'title': '艺术家详情',
                                'fatherName': 'library'
                            }
                        },
                        {
                            'path': '/artist/tracks/:id',
                            'name': 'artist-tracks',
                            'component': 'ArtistTracks',
                            'meta': {
                                'title': '艺术家详情',
                                'fatherName': 'library'
                            }
                        },
                        {
                            'path': '/genres/:sort',
                            'name': 'genres',
                            'component': 'GenreLibrary',
                            'meta': {
                                'title': '风格列表',
                                'fatherName': 'library'
                            }
                        },
                        {
                            'path': '/genre/id/:id',
                            'name': 'genre',
                            'component': 'GenreDetails',
                            'meta': {
                                'title': '风格详情',
                                'fatherName': 'library'
                            }
                        },
                        {
                            'path': '/favourites/:sort',
                            'name': 'favourites',
                            'component': 'Favourites',
                            'meta': {
                                'title': '收藏',
                                'fatherName': 'library'
                            }
                        },
                        {
                            'path': '/playing',
                            'name': 'playing',
                            'component': 'Playing',
                            'meta': {
                                'title': '正在播放',
                                'fatherName': 'library'
                            }
                        },
                        {
                            'path': '/playing/id/:id',
                            'name': 'playingList',
                            'component': 'PlayingList',
                            'meta': {
                                'title': '播放详情',
                                'fatherName': 'library'
                            }
                        },
                        {
                            'path': '/playlists/:sort',
                            'name': 'playlists',
                            'component': 'PlayListLibrary',
                            'meta': {
                                'title': '播放列表',
                                'fatherName': 'library'
                            }
                        },
                        {
                            'path': '/playlist/:id',
                            'name': 'playlist',
                            'component': 'PlayList',
                            'meta': {
                                'title': '播放列表',
                                'fatherName': 'library'
                            }
                        }
                    ]
                },
                {
                    'path': '/index',
                    'name': 'index',
                    'component': 'Index',
                    'meta': {
                        'title': '首页'
                    }
                },
                {
                    'path': '/progress',
                    'name': 'progress',
                    'component': 'ProgressRate',
                    'meta': {
                        'title': '刮削完整度',
                        'fatherName': 'index'
                    }
                },
                {
                    'path': '/record',
                    'name': 'record',
                    'component': 'Record',
                    'meta': {
                        'title': '音乐记录',
                        'fatherName': 'index'
                    }
                },
                {
                    'path': '/mod',
                    'name': 'mod',
                    'component': 'Mod',
                    'meta': {
                        'title': '插件管理'
                    }
                },
                {
                    'path': '/actionMod',
                    'name': 'actionMod',
                    'component': 'ActionMod',
                    'meta': {
                        'title': '插件管理',
                        'fatherName': 'mod'
                    }
                },
                {
                    'path': '/system',
                    'name': 'system',
                    'component': 'System',
                    'meta': {
                        'title': '系统设置'
                    }
                },
                {
                    'path': '/login',
                    'name': 'login',
                    'component': 'Login',
                    'meta': {
                        'title': '登录'
                    }
                }
            ]
            saveObjArr('router', getRouter) // 存储路由到localStorage
            routerGo(to, next) // 执行路由跳转方法
        } else { // 从localStorage拿到了路由
            getRouter = getObjArr('router') // 拿到路由
            routerGo(to, next)
        }
    } else {
        next()
    }
})

function routerGo(to, next) {
    getRouter = filterAsyncRouter(getRouter) // 过滤路由
    router.addRoutes(getRouter) // 动态添加路由
    store.state.antRouter = getRouter // 将路由数据传递给全局变量
    // store.state.displayMenu = getMenuList //将菜单数据传递给全局变量，做侧边栏菜单渲染工作
    // store.state.displayButton = getButton // 将按钮权限数据传递给全局变量，做页面按钮权限渲染工作
    next({
        ...to,
        replace: true
    })
}

function saveObjArr(name, data) { // localStorage 存储数组对象的方法
    localStorage.setItem(name, JSON.stringify(data))
}

function getObjArr(name) { // localStorage 获取数组对象的方法
    return JSON.parse(window.localStorage.getItem(name))
}

const ROUTER_MAP = {
    'Home': Home,
    'User': User,
    'Login': Login,
    'Library': Library,
    'Index': Index,
    'ProgressRate': ProgressRate,
    'Record': Record,
    'Mod': Mod,
    'System': System,
    'ActionMod': ActionMod,
    'Log': Log,
    'logDetail': logDetail,
    'AlbumLibrary': AlbumLibrary,
    'AlbumDetails': AlbumDetails,
    'ArtistLibrary': ArtistLibrary,
    'ArtistDetails': ArtistDetails,
    'ArtistTracks': ArtistTracks,
    'TrackLibrary': TrackLibrary,
    'GenreLibrary': GenreLibrary,
    'GenreDetails': GenreDetails,
    'Favourites': Favourites,
    'Playing': Playing,
    'PlayListLibrary': PlayListLibrary,
    'PlayList': PlayList,
    'PlayingList': PlayingList
}

function filterAsyncRouter(asyncRouterMap) { // 遍历后台传来的路由字符串，转换为组件对象
    const accessedRouters = asyncRouterMap.filter(route => {
        if (route.component) {
            route.component = ROUTER_MAP[route.component]
        }
        if (route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children)
        }
        return true
    })
    return accessedRouters
}

// 用来控制按钮的显示与否
export function hasPermission(permission) {
    const myBtns = store.state.permission.btnPermission
    for (let i = 0; i < myBtns.length; i++) {
        if (myBtns[i].url === permission) {
            return myBtns[i].auth
        }
    }
}
