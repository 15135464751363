<template>
    <div class="overflow-y-auto h-screen-header mt-2" ref="viewhome">
        <div class="tabs tabs-boxed mb-2 ml-2 mr-2 bg-base-100 !flex">
            <div class="flex justify-between w-full">
                <div>
                    <div v-for="{ value, text } in options" :key="value"
                       :class="{ 'tab': true, 'tab-active': sortValue === value }"
                         @click="handleTab(value)">
                        {{ text }}
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="overflow-x-auto">
                <table class="table">
                    <!-- head -->
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>标题</th>
                        <th>艺术家</th>
                        <th class="!hidden md:!table-cell">专辑</th>
                        <th class="!hidden md:!table-cell">年份</th>
                        <th class="!hidden md:!table-cell">风格</th>
                        <th class="!hidden md:!table-cell">歌词</th>
                        <th class="!hidden md:!table-cell">封面</th>
                        <th>操作</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in tracks" :key="index">
                        <th>
                            {{ index + 1 }}
                        </th>
                        <td>
                            <div class="truncate text-ellipsis w-24 md:w-48">{{ item.name }}</div>
                        </td>
                        <td>{{ item.artist_name }}</td>
                        <td class="!hidden md:!table-cell">{{ item.album_name }}</td>
                        <td class="!hidden md:!table-cell">{{ item.year }}</td>
                        <td class="!hidden md:!table-cell">{{ item.genre }}kps</td>
                        <td class="!hidden md:!table-cell">{{ item.lyrics }}</td>
                        <td class="!hidden md:!table-cell">{{ item.album_cover }}</td>
                        <td class="!hidden md:!table-cell">
                            <button class="btn btn-active btn-sm ml-2" @click.stop="redirctEditDetail(item.path)">
                                <svg t="1702603778129" class="h-5 w-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3152" width="64" height="64"><path d="M862.709333 116.042667a32 32 0 1 1 45.248 45.248L455.445333 613.813333a32 32 0 1 1-45.258666-45.258666L862.709333 116.053333zM853.333333 448a32 32 0 0 1 64 0v352c0 64.8-52.533333 117.333333-117.333333 117.333333H224c-64.8 0-117.333333-52.533333-117.333333-117.333333V224c0-64.8 52.533333-117.333333 117.333333-117.333333h341.333333a32 32 0 0 1 0 64H224a53.333333 53.333333 0 0 0-53.333333 53.333333v576a53.333333 53.333333 0 0 0 53.333333 53.333333h576a53.333333 53.333333 0 0 0 53.333333-53.333333V448z"></path></svg>
                            </button>
                        </td>
                        <td class="!table-cell md:!hidden">...</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="flex justify-center" v-if="loading">
            <span class="loading loading-infinity loading-lg"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: "progressRate",
    data() {
        return {
            sortValue: 'miss-album',
            loading: false,
            options: [
                {text: '缺失专辑', value: 'miss-album'},
                {text: '缺失艺术家', value: 'miss-artist'},
                {text: '缺失风格', value: 'miss-genre'},
                {text: '缺失年份', value: 'miss-year'},
                {text: '缺失歌词', value: 'miss-lyrics'},
                {text: '缺失封面', value: 'miss-cover'}
            ],
            tracks: []
        }
    },
    created() {
        this.fetchProgressRate()
    },
    methods: {
        fetchProgressRate() {
            this.loading = true
            this.$api.Task.getProgressRate({'mode': this.sortValue, page: 1, page_size: 50}).then(res => {
                this.loading = false
                if (res.result) {
                    this.tracks = res.data.items
                }
            })
        },
        handleTab(value) {
            this.sortValue = value
            this.fetchProgressRate()
        },
        formatDuration(value) {
            if (!isFinite(value)) {
                return '∞'
            }
            const minutes = Math.floor(value / 60)
            const seconds = Math.floor(value % 60)
            return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds
        },
        formatSize(value) {
            return (value / 1024 / 1024).toFixed(1) + ' MB'
        },
        redirctEditDetail(path) {
            if (this.$route.name !== 'home') {
                this.$router.push({name: 'home'})
            }
            this.$store.commit('setFullPath', path)
        }
    }
}
</script>

<style scoped>

</style>
