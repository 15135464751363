<template>
    <div class="mt-2" ref="viewhome">
        <div class="flex justify-center my-2">
            <div role="tablist" class="tabs tabs-bordered">
                <a role="tab" class="tab tab-active">Last.week</a>
                <a role="tab" class="tab">Last.month</a>
                <a role="tab" class="tab">Last.year</a>
            </div>
        </div>
        <div class="h-80 shadow-xl rounded-xl bg-base-100 mx-2 p-8">
            <div>
                <div class="font-medium">
                    2024年 9 月 1 日 - 2024年 9 月 7 日
                </div>
                <div class="divider divider-neutral !my-0"></div>
                <div class="flex flex-wrap">
                    <div class="w-1/2 mt-14 ml-4">
                        <div>音乐播放</div>
                        <div class="text-6xl font-medium">734</div>
                        <div class="badge badge-outline"> 289% vs. 上周</div>
                        <div class="text-sm font-light mt-1">收藏过等于我听过！</div>
                    </div>
                    <div id="weekly" class="h-72 flex-1"></div>
                </div>
            </div>
        </div>
        <div class="flex flex-col md:flex-row mx-2 mt-4 justify-center">
            <div class="md:w-96 font-medium">最佳音乐</div>
            <div class="md:w-96 invisible">最佳音乐</div>
            <div class="md:w-96 invisible">最佳音乐</div>
        </div>
        <div class="flex flex-col md:flex-row mx-2 mt-1 justify-center">
            <div class="shadow-xl rounded-xl md:w-96 mr-2">
                <div class="bg-base-100 h-24 shadow-xl rounded-xl p-4 bg-neutral text-white" id="example-neutral">
                    <div class="flex items-center">
                        <div>艺术家</div>
                        <div class="badge badge-outline ml-2 text-xs">10%</div>
                    </div>
                    <div class="mt-1 flex justify-between">
                        <div class="text-4xl">47</div>
                        <div class="flex">
                            <svg t="1727701285115" class="fill-white w-8 h-8" viewBox="0 0 1024 1024" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" p-id="1502" width="200" height="200">
                                <path
                                    d="M160 608c-19.2 0-32 12.8-32 32v128c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-128c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v128c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-128c0-54.4 41.6-96 96-96z m320-320c-19.2 0-32 12.8-32 32v512c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-512c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v512c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-512c0-54.4 41.6-96 96-96z m320 320c-19.2 0-32 12.8-32 32v256c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-256c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v256c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-256c0-54.4 41.6-96 96-96z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <div class="tile-item h-full relative">
                        <img src="http://127.0.0.1:8005/static/dist/img/album_null.jpg"
                             class="rounded-t-xl contrast-75">
                        <div class="px-4 absolute bottom-1 w-full">
                            <div class="px-2 bg-neutral w-max text-sm text-white rounded">最佳艺术家</div>
                            <div class="flex justify-between text-white items-end">
                                <div class="text-xl font-light">#1 周杰伦</div>
                                <div class="text-sm font-light">233次记录</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between px-4 mt-2">
                        <div class="text-sm">#1 法老</div>
                        <div class="text-sm">233</div>
                    </div>
                    <div class="flex justify-between px-4">
                        <div class="text-sm">#1 法老</div>
                        <div class="text-sm">233</div>
                    </div>
                    <div class="flex justify-between px-4">
                        <div class="text-sm">#1 法老</div>
                        <div class="text-sm">233</div>
                    </div>
                    <div class="flex justify-between px-4 mb-2">
                        <div class="text-sm">#1 法老</div>
                        <div class="text-sm">233</div>
                    </div>
                </div>
                <div class="mt-2 bg-base-100 rounded-xl p-4">
                    <div class="flex justify-between">
                        <div class="w-3/4">
                            <div class="bg-neutral w-max px-2 text-white rounded">最佳新艺术家</div>
                            <div class="font-light text-sm px-1">13次记录</div>
                            <div class="text-sm px-1">JAY</div>
                        </div>
                        <div class="flex-1">
                            <div class="tile-item h-full">
                                <img src="http://127.0.0.1:8005/static/dist/img/album_null.jpg"
                                     class="rounded-xl contrast-75">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="shadow-xl rounded-xl md:w-96 mr-2">
                <div class="bg-base-100 h-24 shadow-xl rounded-xl p-4 bg-neutral-content">
                    <div class="flex items-center">
                        <div>专辑</div>
                        <div class="badge badge-outline ml-2 text-xs">10%</div>
                    </div>
                    <div class="mt-1 flex justify-between">
                        <div class="text-4xl">120</div>
                        <div class="flex">
                            <svg t="1727701285115" class="fill-current w-8 h-8" viewBox="0 0 1024 1024" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" p-id="1502" width="200" height="200">
                                <path
                                    d="M160 608c-19.2 0-32 12.8-32 32v128c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-128c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v128c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-128c0-54.4 41.6-96 96-96z m320-320c-19.2 0-32 12.8-32 32v512c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-512c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v512c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-512c0-54.4 41.6-96 96-96z m320 320c-19.2 0-32 12.8-32 32v256c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-256c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v256c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-256c0-54.4 41.6-96 96-96z"
                                    fill="#374E6B" p-id="1503"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <div class="tile-item h-full relative">
                        <img src="http://127.0.0.1:8005/static/dist/img/album_null.jpg"
                             class="rounded-t-xl contrast-75">
                        <div class="px-4 absolute bottom-1 w-full">
                            <div class="px-2 bg-neutral-content w-max text-sm rounded">最佳专辑</div>
                            <div class="flex justify-between text-white items-end">
                                <div class="text-xl font-light">#1 周杰伦</div>
                                <div class="text-sm font-light">233次记录</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between px-4 mt-2">
                        <div class="text-sm">#1 法老</div>
                        <div class="text-sm">233</div>
                    </div>
                    <div class="flex justify-between px-4">
                        <div class="text-sm">#1 法老</div>
                        <div class="text-sm">233</div>
                    </div>
                    <div class="flex justify-between px-4">
                        <div class="text-sm">#1 法老</div>
                        <div class="text-sm">233</div>
                    </div>
                    <div class="flex justify-between px-4 mb-2">
                        <div class="text-sm">#1 法老</div>
                        <div class="text-sm">233</div>
                    </div>
                </div>
                <div class="mt-2 bg-base-100 rounded-xl p-4">
                    <div class="flex justify-between">
                        <div class="w-3/4">
                            <div class="bg-neutral-content w-max px-2 rounded">最佳新专辑</div>
                            <div class="font-light text-sm px-1">13次记录</div>
                            <div class="text-sm px-1">JAY</div>
                        </div>
                        <div class="flex-1">
                            <div class="tile-item h-full">
                                <img src="http://127.0.0.1:8005/static/dist/img/album_null.jpg"
                                     class="rounded-xl contrast-75">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="shadow-xl rounded-xl md:w-96 mr-2">
                <div class="h-24 shadow-xl rounded-xl p-4 bg-accent" id="example-accent">
                    <div class="flex items-center">
                        <div>单曲</div>
                        <div class="badge badge-outline ml-2 text-xs">10%</div>
                    </div>
                    <div class="mt-1 flex justify-between">
                        <div class="text-4xl">120</div>
                        <div class="flex">
                            <svg t="1727701285115" class="fill-current w-8 h-8" viewBox="0 0 1024 1024" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" p-id="1502" width="200" height="200">
                                <path
                                    d="M160 608c-19.2 0-32 12.8-32 32v128c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-128c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v128c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-128c0-54.4 41.6-96 96-96z m320-320c-19.2 0-32 12.8-32 32v512c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-512c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v512c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-512c0-54.4 41.6-96 96-96z m320 320c-19.2 0-32 12.8-32 32v256c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-256c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v256c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-256c0-54.4 41.6-96 96-96z"
                                    fill="#374E6B" p-id="1503"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <div class="tile-item h-full relative">
                        <img src="http://127.0.0.1:8005/static/dist/img/album_null.jpg"
                             class="rounded-t-xl contrast-75">
                        <div class="px-4 absolute bottom-1 w-full">
                            <div class="px-2 bg-accent w-max text-sm rounded">最佳单曲</div>
                            <div class="flex justify-between text-white items-end">
                                <div class="text-xl font-light">#1 周杰伦</div>
                                <div class="text-sm font-light">233次记录</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between px-4 mt-2">
                        <div class="text-sm">#1 法老</div>
                        <div class="text-sm">233</div>
                    </div>
                    <div class="flex justify-between px-4">
                        <div class="text-sm">#1 法老</div>
                        <div class="text-sm">233</div>
                    </div>
                    <div class="flex justify-between px-4">
                        <div class="text-sm">#1 法老</div>
                        <div class="text-sm">233</div>
                    </div>
                    <div class="flex justify-between px-4 mb-2">
                        <div class="text-sm">#1 法老</div>
                        <div class="text-sm">233</div>
                    </div>
                </div>
                <div class="mt-2 bg-base-100 rounded-xl p-4">
                    <div class="flex justify-between">
                        <div class="w-3/4">
                            <div class="bg-accent w-max px-2 rounded">最佳新单曲</div>
                            <div class="font-light text-sm px-1">13次记录</div>
                            <div class="text-sm px-1">JAY</div>
                        </div>
                        <div class="flex-1">
                            <div class="tile-item h-full">
                                <img src="http://127.0.0.1:8005/static/dist/img/album_null.jpg"
                                     class="rounded-xl contrast-75">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ml-4 mt-2 font-medium">
            榜单
        </div>
        <div class="h-80 flex flex-col md:flex-row mx-2 mt-2">
            <div class="shadow-xl rounded-xl md:w-1/2 bg-base-100 mr-2">
                <div class="m-4 font-medium">最热风格</div>
                <div id="hot-tag" class="h-64"></div>
            </div>
            <div class="shadow-xl rounded-xl md:w-1/2 bg-base-100 mr-2">
                <div class="m-4 font-medium">音乐年代</div>
                <div id="year" class="h-64"></div>
            </div>
        </div>
        <div class="h-80 flex flex-col md:flex-row mx-2 mt-2">
            <div class="shadow-xl rounded-xl md:w-1/2 bg-base-100 mr-2">
                <div class="m-4 font-medium">平台分布</div>
                <div id="plat" class="h-64"></div>
            </div>
            <div class="shadow-xl rounded-xl md:w-1/2 bg-base-100 mr-2">
                <div class="m-4 font-medium">收听时钟</div>
                <div id="listen" class="h-64"></div>
            </div>
        </div>
        <div class="ml-4 mt-2 font-medium">
            快速事实
        </div>
        <div class="h-40 flex flex-col md:flex-row mx-2 mt-2 mb-4">
            <div class="shadow-xl rounded-xl md:w-1/3 bg-base-100 mr-2 p-4 h-full">
                <div class="flex flex-col">
                    <div class="flex items-center">
                        <div>收听时间</div>
                        <div class="badge badge-outline text-xs font-light ml-2">200%</div>
                    </div>
                    <div class="text-4xl mt-4 font-medium">
                        1天，14小时
                    </div>
                    <div class="text-base-400 font-light mt-4 ml-2">
                        vs 11小时(上周)
                    </div>
                </div>
            </div>
            <div class="shadow-xl rounded-xl md:w-1/3 bg-base-100 mr-2 p-4 h-full">
                <div class="flex flex-col">
                    <div class="flex items-center">
                        <div>平均记录</div>
                        <div class="badge badge-outline text-xs font-light ml-2">200%</div>
                    </div>
                    <div class="text-4xl mt-4 font-medium">
                        105/天
                    </div>
                    <div class="text-base-400 font-light mt-4 ml-2">
                        vs 11小时(上周)
                    </div>
                </div>
            </div>
            <div class="shadow-xl rounded-xl md:w-1/3 bg-base-100 mr-2 p-4 h-full">
                <div class="flex flex-col">
                    <div class="flex items-center">
                        <div>最活跃一天</div>
                        <div class="badge badge-outline text-xs font-light ml-2">200%</div>
                    </div>
                    <div class="text-4xl mt-4 text-accent font-medium">
                        294 sep 21 日
                    </div>
                    <div class="text-base-400 font-light mt-4 ml-2">
                        vs 11小时(上周)
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "record",
    methods: {
        oklchToHex(oklchString) {
            const culori = require('culori');

            const match = oklchString.match(/oklch\(([\d.]+)\s+([\d.]+)\s+([\d.]+)\)/);
            if (!match) {
                throw new Error('Invalid OKLCH string');
            }

            const [, l, c, h] = match.map(Number);

            // 使用culori将OKLCH转换为RGB
            const rgb = culori.converter('rgb')({mode: 'oklch', l, c, h});

            // 将RGB值限制在0-1范围内，然后转换为0-255范围的整数
            const clamp = (value) => Math.max(0, Math.min(1, value));
            const r = Math.round(clamp(rgb.r) * 255);
            const g = Math.round(clamp(rgb.g) * 255);
            const b = Math.round(clamp(rgb.b) * 255);

            // 将RGB转换为16进制
            const toHex = (value) => {
                const hex = value.toString(16);
                return hex.length === 1 ? '0' + hex : hex;
            };

            return '#' + toHex(r) + toHex(g) + toHex(b);
        },
        draw() {
            // 从元素的计算样式中获取颜色
            let accentColor = window.getComputedStyle(document.getElementById('example-accent'))['backgroundColor']
            accentColor = this.oklchToHex(accentColor)
            let neutralColor = window.getComputedStyle(document.getElementById('example-neutral'))['backgroundColor']
            neutralColor = this.oklchToHex(neutralColor)
            const myChart = this.$echarts.init(document.getElementById('weekly'))
            const option = {
                legend: {},
                color: [neutralColor, accentColor],
                tooltip: {},
                dataset: {
                    source: [
                        ['product', '上周', '本周'],
                        ['周一', 43.3, 85.8],
                        ['周二', 83.1, 73.4],
                        ['周三', 32.1, 10],
                        ['周四', 66, 44],
                        ['周五', 34, 63.4],
                        ['周六', 12, 73.4],
                        ['周日', 66, 3.4],
                    ]
                },
                xAxis: {
                    type: 'category',
                    axisLine: {
                        show: false
                    }
                },
                yAxis: [{
                    type: "value",
                    show: false
                }],
                // Declare several bar series, each will be mapped
                // to a column of dataset.source by default.
                series: [
                    {type: 'bar'},
                    {type: 'bar'}
                ]
            };
            myChart.setOption(option)
        },
        drawHotTag() {
            const myChart = this.$echarts.init(document.getElementById('hot-tag'))
            const option = {
                color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
                title: {
                    text: ''
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    data: ['Line 1', 'Line 2', 'Line 3', 'Line 4', 'Line 5']
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: 'Line 1',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgb(128, 255, 165)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(1, 191, 236)'
                                }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [140, 232, 101, 264, 90, 340, 250]
                    },
                    {
                        name: 'Line 2',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgb(0, 221, 255)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(77, 119, 255)'
                                }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [120, 282, 111, 234, 220, 340, 310]
                    },
                    {
                        name: 'Line 3',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgb(55, 162, 255)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(116, 21, 219)'
                                }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [320, 132, 201, 334, 190, 130, 220]
                    },
                    {
                        name: 'Line 4',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgb(255, 0, 135)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(135, 0, 157)'
                                }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [220, 402, 231, 134, 190, 230, 120]
                    },
                    {
                        name: 'Line 5',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        label: {
                            show: true,
                            position: 'top'
                        },
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgb(255, 191, 0)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(224, 62, 76)'
                                }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [220, 302, 181, 234, 210, 290, 150]
                    }
                ]
            };
            myChart.setOption(option)
        },
        drawYear() {
            let accentColor = window.getComputedStyle(document.getElementById('example-accent'))['backgroundColor']
            accentColor = this.oklchToHex(accentColor)
            const myChart = this.$echarts.init(document.getElementById('year'))
            const option = {
                title: {
                    text: ''
                },
                color: [accentColor],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01],
                    show: true
                },
                yAxis: {
                    type: 'category',
                    data: ['Brazil', 'Indonesia', 'USA', 'India', 'China', 'World']
                },
                series: [
                    {
                        name: '',
                        type: 'bar',
                        data: [18203, 23489, 29034, 104970, 131744, 630230]
                    }
                ]
            };
            myChart.setOption(option)
        },
        drawPlat() {
            const myChart = this.$echarts.init(document.getElementById('plat'))
            const option = {
                legend: {
                    top: 'bottom'
                },
                toolbox: {
                    show: true,
                    feature: {
                        mark: {show: true},
                        dataView: {show: true, readOnly: false},
                        restore: {show: true},
                        saveAsImage: {show: true}
                    }
                },
                series: [
                    {
                        name: 'Nightingale Chart',
                        type: 'pie',
                        radius: [40, 100],
                        center: ['50%', '50%'],
                        roseType: 'area',
                        itemStyle: {
                            borderRadius: 8
                        },
                        data: [
                            {value: 40, name: 'rose 1'},
                            {value: 38, name: 'rose 2'},
                            {value: 32, name: 'rose 3'},
                            {value: 30, name: 'rose 4'},
                            {value: 28, name: 'rose 5'},
                            {value: 26, name: 'rose 6'},
                            {value: 22, name: 'rose 7'},
                            {value: 18, name: 'rose 8'}
                        ]
                    }
                ]
            };
            myChart.setOption(option)
        },
        drawListen() {
            const myChart = this.$echarts.init(document.getElementById('listen'))
            var data = [
                {
                    name: 'Grandpa',
                    children: [
                        {
                            name: 'Uncle Leo',
                            value: 15,
                            children: [
                                {
                                    name: 'Cousin Jack',
                                    value: 2
                                },
                                {
                                    name: 'Cousin Mary',
                                    value: 5,
                                    children: [
                                        {
                                            name: 'Jackson',
                                            value: 2
                                        }
                                    ]
                                },
                                {
                                    name: 'Cousin Ben',
                                    value: 4
                                }
                            ]
                        },
                        {
                            name: 'Father',
                            value: 10,
                            children: [
                                {
                                    name: 'Me',
                                    value: 5
                                },
                                {
                                    name: 'Brother Peter',
                                    value: 1
                                }
                            ]
                        }
                    ]
                },
                {
                    name: 'Nancy',
                    children: [
                        {
                            name: 'Uncle Nike',
                            children: [
                                {
                                    name: 'Cousin Betty',
                                    value: 1
                                },
                                {
                                    name: 'Cousin Jenny',
                                    value: 2
                                }
                            ]
                        }
                    ]
                }
            ];
            const option = {
                series: {
                    type: 'sunburst',
                    data: data,
                    radius: [60, '90%'],
                    itemStyle: {
                        borderRadius: 7,
                        borderWidth: 2
                    },
                    label: {
                        show: false
                    }
                }
            };
            myChart.setOption(option)
        },
    },
    mounted() {
        this.draw()
        this.drawHotTag()
        this.drawYear()
        this.drawPlat()
        this.drawListen()
    },
    created() {
    }
}
</script>

<style scoped>
.tile-item {
    width: 100%;
}

.tile-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
