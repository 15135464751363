<template>
    <div>
        <div role="tablist" class="tabs tabs-boxed bg-base-100 mx-2 mt-2 !flex">
            <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect2 === 'log' }"
                @click="handleTab2('log')">操作日志</a>
            <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect2 === 'runtime' }"
                @click="handleTab2('runtime')">运行日志</a>
        </div>
        <div v-if="tabSelect2 === 'log'">
            <div role="tablist" class="tabs tabs-boxed bg-base-100 mx-2 mt-2 !flex">
                <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect === 'info' }"
                    @click="handleTabInfo('info')">Info</a>
                <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect === 'warning' }"
                    @click="handleTabInfo('warning')">Warning</a>
                <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect === 'error' }"
                    @click="handleTabInfo('error')">Error</a>
            </div>
            <div class="overflow-x-auto bg-base-100 m-2 rounded-xl">
                <table class="table table-zebra">
                    <!-- head -->
                    <thead>
                        <tr>
                            <th></th>
                            <th>用户</th>
                            <th>日志等级</th>
                            <th>操作</th>
                            <th>详情</th>
                            <th>操作时间</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in auditLogData" :key="index" class="cursor-pointer hover:bg-gray-100">
                            <th>{{item.id}}</th>
                            <td>{{item.user}}</td>
                            <td>
                                <span v-if="item.level === 'info'" class="badge">
                                    {{item.level}}
                                </span>
                                <span v-else-if="item.level === 'error'" class="badge badge-secondary">
                                    {{item.level}}
                                </span>
                                <span v-else-if="item.level === 'warning'" class="badge badge-warning">
                                    {{item.level}}
                                </span>
                            </td>
                            <td>{{item.action}}</td>
                            <td class="max-w-md break-words">
                                {{item.extra}}
                                <a class="link link-accent" v-if="hasLogDetail.indexOf(item.action) !== -1" @click="handleRedirect(item)">查看详情</a>
                            </td>
                            <td>{{item.created_at}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else>
            <div class="flex flex-col md:flex-row">
                <div class="m-2 rounded-xl md:w-1/2">
                    <textarea class="textarea textarea-bordered h-screen-log w-full" placeholder="运行日志" v-model="runtimeText">
                    </textarea>
                </div>
                <div class="m-2 rounded-xl md:w-1/2">
                    <textarea class="textarea textarea-bordered h-screen-log w-full" placeholder="错误日志" v-model="runtimeTextFail">
                    </textarea>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                size: 'small',
                auditLogData: [],
                tabSelect: '',
                tabSelect2: 'log',
                runtimeText: '',
                runtimeTextFail: '',
                hasLogDetail: ['导入收藏', '自动刮削', '后台刮削', '重复检查']
            }
        },
        created() {
            this.fetchAuditLog()
        },
        methods: {
            handlePageChange(page) {
                this.pagination.current = page
            },
            fetchAuditLog() {
                this.$api.Task.getAuditLog({page_size: 50, level: this.tabSelect}).then(res => {
                    if (res.result) {
                        this.auditLogData = res.data.items
                    }
                })
            },
            fetchRuntimeLog() {
                this.$api.Task.getRuntimeLog().then(res => {
                    if (res.result) {
                        this.runtimeText = res.data.log
                        this.runtimeTextFail = res.data.fail_log
                    }
                })
            },
            handleTabInfo(v) {
                if (v === this.tabSelect) {
                    this.tabSelect = ''
                } else {
                    this.tabSelect = v
                }
                this.fetchAuditLog()
            },
            handleTab2(v) {
                this.tabSelect2 = v
                if (v === 'log') {
                    this.fetchAuditLog()
                } else {
                    this.fetchRuntimeLog()
                }
            },
            handleRedirect(item) {
                this.$router.push({path: '/log_detail/' + item.id + '/'})
            }
        }
    }
</script>
<style lang="postcss">
.bk-table-header .custom-header-cell {
    color: inherit;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-position: under;
}
.h-screen-log {
    height: calc(100vh - 140px);
}
</style>
