<template functional>
    <div class="bg-base-100 rounded-xl shadow-xl border-solid hover:border-2 hover:border-primary">
        <div class="tile-img">
            <router-link :to="props.to">
                <img v-if="props.image" :src="props.image" class="rounded-t-xl">
                <img v-else src="/static/dist/img/album_null.jpg" class="rounded-t-xl">
            </router-link>
            <slot name="play">
            </slot>
        </div>
        <div class="card-music">
            <div class="truncate font-weight-bold link link-hover hover:text-gray-400">
                <slot name="title">
                    <router-link :to="props.to">
                        {{ props.title }}
                    </router-link>
                </slot>
            </div>
            <div class="truncate text-muted link link-hover text-gray-400">
                <slot name="text">
                    {{ props.text }}
                </slot>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        image: {type: String, default: null},
        to: {type: [String, Object], default: null},
        title: {type: String, default: null},
        text: {type: String, default: null},
    },
    components: {}
}
</script>
<style>
.tile-img {
    position: relative;
    width: 100%;
}

.tile-img img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-music {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 2rem;
    gap: 0.5rem;
}

@media (max-width: 442px) {
    .card-music {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding: 1rem;
        gap: 0.5rem;
    }
}
</style>
