<template>
    <div class="flex flex-col h-screen-header">
        <div>
            <div class="flex flex-col md:flex-row">
                <div class="bg-base-100 md:w-1/2 md:h-60 shadow-xl rounded-xl m-2 flex flex-col md:flex-row md:justify-between">
                    <div class="p-8">
                        <div class="text-secondary stat-value">Welcome back {{getUsername}}</div>
                        <div class="mt-4 indent-4 text-gray-500">
                            这里什么都没有，只有音乐。我们希望您能在这里找到自己的音乐天堂，体验音乐的力量和魅力。
                        </div>
                        <div class="mt-4 indent-4 flex" v-if="track">
                            <div class="text-gray-500">
                                正在播放:
                            </div>
                            <div class="text-gray-500 hover:underline cursor-pointer" @click="handleRedirect('playing')">
                                {{track.artist}}-{{track.title}}
                            </div>
                        </div>
                    </div>
                    <img src="/static/dist/img/play_music.svg" alt="Album" class="h-64 z-1" />
                </div>
                <div class="m-2 flex flex-col grow">
                    <div class="stats stats-vertical md:stats-horizontal shadow-xl">
                        <div class="stat">
                            <div class="stat-figure text-primary">
                                <svg t="1695911542390" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="6940" width="32" height="32" fill="#4818e5">
                                    <path
                                        d="M727.168548 648.936789c0.00614 0 0.010233-0.002047 0.01535-0.002047 0.008186 0 0.01535 0.002047 0.021489 0.002047L727.168548 648.936789zM864.120686 785.871531l0-19.562545c0 64.823378-61.306274 117.374244-136.935766 117.374244-75.626421 0-136.935766-52.549843-136.935766-117.374244 0-47.939849 33.633005-89.020476 81.690534-107.244536l0.062422 0.121773c4.909823-2.220574 10.31902-3.52529 16.058744-3.52529 21.608134 0 39.125089 17.517979 39.125089 39.125089 0 14.296611-7.748475 26.671453-19.194154 33.498952l0.575098 1.101077c-23.228027 5.211698-40.067554 19.683295-40.067554 36.923958 0 21.608134 26.276457 39.125089 58.68661 39.125089 32.413224 0 58.68661-17.515932 58.68661-39.125089 0-0.563842-0.212848-1.085728-0.248663-1.64343l18.588357-115.728768-0.281409 0 42.135656-273.874601-314.502927 0c-21.60711 0-39.125089-17.515932-39.125089-39.123043 0-21.61018 17.517979-39.125089 39.125089-39.125089l326.540078 0 6.019087-39.126112c0-21.608134-17.515932-39.123043-39.125089-39.123043l-352.119663 0c-21.608134 0-39.125089 17.513886-39.125089 39.123043l-78.249155 508.62002c0 64.823378-61.310368 117.374244-136.937812 117.374244S81.630159 831.132364 81.630159 766.308987s61.306274-117.374244 136.933719-117.374244c27.913746 0 53.809533 7.241938 75.459622 19.532869l61.47819-430.339142c0-54.021358 43.790342-97.8117 97.8117-97.8117l391.245775 0c54.019311 0 97.809653 43.790342 97.809653 97.8117L864.120686 785.871531zM218.563878 727.183897c-32.411177 0-58.68661 17.517979-58.68661 39.125089 0 21.608134 26.276457 39.125089 58.68661 39.125089 32.413224 0 58.68661-17.515932 58.68661-39.125089C277.251511 744.701876 250.977102 727.183897 218.563878 727.183897z"
                                        fill="" p-id="6941"></path>
                                </svg>
                            </div>
                            <div class="stat-title">全部歌曲</div>
                            <div class="stat-value text-primary cursor-pointer" @click="handleRedirect('tracks')">{{ musicCount.track_count }}</div>
                            <div class="stat-desc">{{musicCount.track_mom}}% more than last month</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg t="1695911827315" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="12843" width="32" height="32" fill="#dc2fb3">
                                    <path
                                        d="M548.181333 857.565867a68.266667 68.266667 0 0 1-48.469333-20.0704L180.224 517.870933a68.266667 68.266667 0 0 1 0-96.802133L472.2688 129.024a174.08 174.08 0 0 1 245.76 0l170.666667 170.530133a174.08 174.08 0 0 1 0 245.76L596.650667 837.495467a68.266667 68.266667 0 0 1-48.469334 20.0704z m-9.8304-58.709334a13.653333 13.653333 0 0 0 19.6608 0l292.0448-291.908266a119.466667 119.466667 0 0 0 0-168.7552L679.5264 167.662933a119.466667 119.466667 0 0 0-168.7552 0L218.453333 459.707733a13.653333 13.653333 0 0 0 0 19.6608z"
                                        fill="" p-id="12844"></path>
                                    <path
                                        d="M366.728533 900.437333a78.097067 78.097067 0 0 1-55.432533-22.9376L140.219733 706.423467a78.506667 78.506667 0 0 1 0-110.865067L218.453333 517.461333a28.125867 28.125867 0 0 1 38.638934 0l243.165866 243.3024a27.306667 27.306667 0 0 1 0 38.638934l-78.097066 78.097066a78.097067 78.097067 0 0 1-55.432534 22.9376z m-129.024-324.949333l-58.845866 58.709333a23.7568 23.7568 0 0 0 0 33.5872l171.076266 171.076267a23.7568 23.7568 0 0 0 33.5872 0l58.845867-58.845867zM612.488533 496.4352l-23.3472-23.893333a8.6016 8.6016 0 0 1 0.8192-12.6976l69.495467-54.613334a14.609067 14.609067 0 0 0-9.8304-26.077866l-125.064533 7.099733a14.609067 14.609067 0 0 0-9.557334 24.712533l23.3472 23.893334a8.6016 8.6016 0 0 1-0.8192 12.6976l-69.495466 54.613333A14.609067 14.609067 0 0 0 477.866667 528.384l125.064533-7.099733a14.609067 14.609067 0 0 0 9.557333-24.849067z"
                                        p-id="12845"></path>
                                </svg>
                            </div>
                            <div class="stat-title">歌曲大小</div>
                            <div class="stat-value text-secondary">{{ musicCount.song_size }}</div>
                            <div class="stat-desc">{{musicCount.track_mom}}% more than last month</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <div class="avatar">
                                    <div class="w-16">
                                        <img src="/static/dist/img/music_null-cutout.png" />
                                    </div>
                                </div>
                            </div>
                            <div class="stat-value cursor-pointer" @click="handleRedirect('progress')">{{ musicCount.finished_rate }}</div>
                            <div class="stat-title">刮削完整度</div>
                            <div class="stat-desc text-secondary">{{musicCount.active_tasks_count}} tasks remaining</div>
                        </div>
                    </div>
                    <div class="stats stats-vertical md:stats-horizontal shadow-xl mt-4">

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg class="inline-block w-8 h-8 stroke-current" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="4475" width="32" height="32" fill="#4f14ee">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M511.701333 511.701333m-85.632 0a85.632 85.632 0 1 0 171.264 0 85.632 85.632 0 1 0-171.264 0Z"></path>
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M512 85.333333C276.736 85.333333 85.333333 276.736 85.333333 512s191.402667 426.666667 426.666667 426.666667 426.666667-191.402667 426.666667-426.666667S747.264 85.333333 512 85.333333z m0 768c-188.202667 0-341.333333-153.130667-341.333333-341.333333s153.130667-341.333333 341.333333-341.333333 341.333333 153.130667 341.333333 341.333333-153.130667 341.333333-341.333333 341.333333z"
                                        fill="" p-id="4477"></path>
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M512 256c-141.184 0-256 114.816-256 256h85.333333c0-94.122667 76.544-170.666667 170.666667-170.666667V256z"
                                        fill="" p-id="4478"></path>
                                </svg>
                            </div>
                            <div class="stat-title">全部专辑</div>
                            <div class="stat-value text-secondary cursor-pointer" @click="handleRedirect('albums')">{{ musicCount.album_count }}</div>
                            <div class="stat-desc">↗ {{musicCount.album_diff}} ({{musicCount.album_mom}})%</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg t="1695911766499" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="11691" width="32" height="32" fill="#dc2fb3">
                                    <path
                                        d="M535.253333 255.274667a170.666667 170.666667 0 0 0 234.154667 233.045333c-51.2-23.850667-104.618667-61.866667-138.24-95.488-34.048-34.048-72.149333-87.04-95.914667-137.557333z m65.706667-63.829334c12.928 40.277333 57.301333 107.818667 90.538667 141.056 32.682667 32.682667 99.072 76.074667 141.44 89.813334a170.666667 170.666667 0 0 0-231.936-230.869334z m-9.173333 389.290667a256.768 256.768 0 0 1-148.522667-148.522667l-259.84 373.504 34.858667 34.858667 373.504-259.84z m129.834666 13.653333L208.384 951.424l-135.808-135.808L429.653333 302.378667A256.042667 256.042667 0 0 1 938.666667 341.333333a256.042667 256.042667 0 0 1-217.045334 253.056z"
                                        p-id="11692"></path>
                                </svg>
                            </div>
                            <div class="stat-title">全部艺术家</div>
                            <div class="stat-value text-primary cursor-pointer" @click="handleRedirect('artists')">{{ musicCount.artist_count }}</div>
                            <div class="stat-desc">↗︎ {{ musicCount.artist_diff }} ({{ musicCount.artist_mom }}%)</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg t="1695911923641" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="13865" width="32" height="32" fill="#4818e5">
                                    <path
                                        d="M512 229.034667c82.310095 0 157.793524 29.135238 216.746667 77.628952l-52.028953 52.053333A267.044571 267.044571 0 0 0 512 302.153143c-148.114286 0-268.190476 120.100571-268.190476 268.190476 0 148.138667 120.07619 268.190476 268.190476 268.190476s268.190476-120.05181 268.190476-268.190476c0-14.750476-1.194667-29.257143-3.486476-43.398095l60.513524-60.513524c10.48381 32.768 16.11581 67.705905 16.115809 103.936 0 188.513524-152.81981 341.333333-341.333333 341.333333s-341.333333-152.81981-341.333333-341.333333 152.81981-341.333333 341.333333-341.333333zM808.374857 307.2l51.492572 51.95581-312.076191 309.345523-143.969524-143.969523 51.736381-51.712 92.452572 92.452571 260.388571-258.072381zM636.123429 109.714286v77.58019H377.539048V109.714286H636.099048z"
                                        p-id="13866"></path>
                                </svg>
                            </div>
                            <div class="stat-title">歌曲时长</div>
                            <div class="stat-value">{{ musicCount.song_duration }}</div>
                            <div class="stat-desc">Total elapsed time {{ musicCount.song_duration_raw }}s</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="flex-1">
            <div class="flex flex-col md:flex-row h-full">
                <div class="bg-base-100 shadow-xl rounded-xl m-2 flex-1 overflow-y-auto p-8">
                    <div id="demo" class="h-96 md:h-full"></div>
                </div>
                <div class="bg-base-100 shadow-xl rounded-xl m-2 md:w-96 overflow-y-auto">
                    <div class="mt-4 mb-1 ml-4 flex justify-between items-end">
                        <div class="font-medium">音乐播放</div>
                        <div class="mr-4 font-light text-sm underline cursor-pointer" @click="handleRedirect('record')">More</div>
                    </div>
                    <div role="tablist" class="tabs tabs-boxed bg-base-100 ml-2 !flex">
                        <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect === 'track' }"
                            @click="tabSelect = 'track'">歌曲</a>
                        <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect === 'album' }"
                            @click="tabSelect = 'album'">专辑</a>
                        <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect === 'artist' }"
                            @click="tabSelect = 'artist'">艺术家</a>
                    </div>
                    <div>
                        <div v-if="tabSelect === 'track'">
                            <div class="overflow-x-auto">
                                <table class="table">
                                    <!-- head -->
                                    <tbody>
                                        <!-- row 1 -->
                                        <tr v-for="(item,index) in auditLogData.track_list" :key="index" class="font-light">
                                            <th>{{ index + 1 }}</th>
                                            <td>{{ item.record }}</td>
                                            <td>{{ item.count }}次</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-else-if="tabSelect === 'album'">
                            <div class="overflow-x-auto">
                                <table class="table">
                                    <!-- head -->
                                    <tbody>
                                        <!-- row 1 -->
                                        <tr v-for="(item,index) in auditLogData.album_list" :key="index" class="font-light">
                                            <th>{{ index + 1 }}</th>
                                            <td>{{ item.record }}</td>
                                            <td>{{ item.count }}次</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-else-if="tabSelect === 'artist'">
                            <div class="overflow-x-auto">
                                <table class="table">
                                    <!-- head -->
                                    <tbody>
                                        <!-- row 1 -->
                                        <tr v-for="(item,index) in auditLogData.artist_list" :key="index" class="font-light">
                                            <th>{{ index + 1 }}</th>
                                            <td>{{ item.record }}</td>
                                            <td>{{ item.count }}次</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapGetters} from 'vuex'
    export default {
        data() {
            return {
                size: 'small',
                musicCount: {},
                auditLogData: [],
                tabSelect: 'track'
            }
        },
        computed: {
            ...mapGetters(['getUsername', 'track'])
        },
        created() {
            this.fetchMusicCount()
            this.fetchAuditLog()
        },
        mounted() {
        },
        methods: {
            handlePageChange(page) {
                this.pagination.current = page
            },
            remove(row) {
                const index = this.data.indexOf(row)
                if (index !== -1) {
                    this.data.splice(index, 1)
                }
            },
            draw() {
                const myChart = this.$echarts.init(document.getElementById('demo'))
                const option = {
                    color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
                    title: {
                        text: '音乐月度趋势'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    legend: {
                        data: ['歌曲', '专辑', '艺术家']
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: this.musicCount.trends.x_axis
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: '歌曲',
                            type: 'line',
                            stack: 'Total',
                            smooth: true,
                            lineStyle: {
                                width: 0
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.8,
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgb(128, 255, 165)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgb(1, 191, 236)'
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: this.musicCount.trends.track
                        },
                        {
                            name: '专辑',
                            type: 'line',
                            stack: 'Total',
                            smooth: true,
                            lineStyle: {
                                width: 0
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.8,
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgb(0, 221, 255)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgb(77, 119, 255)'
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: this.musicCount.trends.album
                        },
                        {
                            name: '艺术家',
                            type: 'line',
                            stack: 'Total',
                            smooth: true,
                            lineStyle: {
                                width: 0
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.8,
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgb(55, 162, 255)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgb(116, 21, 219)'
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: this.musicCount.trends.artist
                        }
                    ]
                }
                myChart.setOption(option)
            },
            fetchMusicCount() {
                this.$api.Task.getMusicCount().then(res => {
                    if (res.result) {
                        this.musicCount = res.data
                        this.draw()
                    }
                })
            },
            fetchAuditLog() {
                this.$api.Task.getListeningRecord({page_size: 8}).then(res => {
                    if (res.result) {
                        this.auditLogData = res.data
                    }
                })
            },
            handleRedirect(v) {
                if (v === 'tracks') {
                    this.$router.push({path: '/tracks/recently-added/'})
                } else if (v === 'albums') {
                    this.$router.push({path: '/albums/recently-added/'})
                } else if (v === 'artists') {
                    this.$router.push({path: '/artists/most-albums/'})
                } else if (v === 'playing') {
                    this.$router.push({path: '/playing'})
                } else if (v === 'progress') {
                    this.$router.push({path: '/progress'})
                } else if (v === 'record') {
                    this.$router.push({path: '/record'})
                }
            }
        }
    }
</script>
<style lang="postcss">
.bk-table-header .custom-header-cell {
    color: inherit;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-position: under;
}
</style>
